import type { InquiryCategory } from '@zwirner/design-system'
import { INQUIRY_CATEGORIES } from '@zwirner/design-system'
import dynamic from 'next/dynamic'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  CLOSE,
  JOIN_OUR_MAILING_LIST_ERROR,
  REGISTER_INTEREST,
  REGISTERING_INTEREST_SUCCESS_SUBTITLE,
  REGISTERING_INTEREST_SUCCESS_TITLE,
  SIGN_UP,
  TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS,
} from '@/common/constants/commonCopies'
import { ErrorType, GTMErrorMessageEvent } from '@/common/utils/gtm/GTMErrorMessageEvent'
import { gtmPopupClickedEvent, TypeTypes } from '@/common/utils/gtm/gtmPopupEvent'
import { useInquiryType } from '@/common/utils/inquire/inquiryType'
import { captchaInitObserver, removeCaptchaObserver } from '@/common/utils/recaptcha/observer'
import { getNewYorkTimestamp } from '@/common/utilsMappers/date.mapper'
import useGtmRegisteredInterestEvent from '@/components/hooks/gtm/useGtmRegisteredInterestEvent'
import { MethodTypes, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { createRandomUUID } from '@/sanity/uuid'
import {
  type InquireData,
  type RegisteredInterestData,
  sendRegisteredInterestRequest,
} from '@/services/subscribeService'
import { useLandingPageStore } from '@/store/landingPageStore'

import type { CommonModalProps } from './types'

const RecaptchaNode = dynamic(
  () => import('@/components/forms/recaptchaNode').then((mod) => mod.RecaptchaNode),
  {
    ssr: false,
  }
)

const FORM_ID_INQUIRY = 'inquiry'

type Props = {
  disableBackdrop?: boolean
  usePortalForRecaptcha?: boolean
}

export type RegisteredInterestModalProps =
  | (CommonModalProps & {
      inquiryCategory: InquiryCategory
    })
  | null

export const useRegisteredInterestFormModal = (props?: Props) => {
  const { disableBackdrop = false, usePortalForRecaptcha = false } = props ?? {}
  const { url: landingPageURL = '' } = useLandingPageStore((state) => state.landingPageURL)
  const [isOpen, setIsOpen] = useState(false)
  const [customModalProps, setCustomModalProps] = useState<RegisteredInterestModalProps>(null)
  const [triggerType, setTriggerType] = useState<ModalTriggerTypes | null>(null)
  const recaptchaRef = useRef<HTMLFormElement>()
  const inquiryType = useInquiryType()
  const { gtmRegisteredInterestStartedEvent, gtmRegisteredInterestSubmittedEvent } =
    useGtmRegisteredInterestEvent()
  const onDirty = gtmRegisteredInterestStartedEvent
  const onClose = () => {
    setIsOpen(false)
    setCustomModalProps(null)
    if (triggerType === ModalTriggerTypes.POPUP) {
      gtmPopupClickedEvent({
        cta_value: SIGN_UP,
        method: MethodTypes.CENTER,
        type: TypeTypes.FORM,
        link_url: CLOSE,
      })
    }
  }

  const registeredInterestFormProps = {
    title: customModalProps?.title || REGISTER_INTEREST,
    subtitle: customModalProps?.portableTextSubtitle
      ? null
      : customModalProps?.subtitle || TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS,
    portableTextSubtitle: customModalProps?.portableTextSubtitle,
    termsAndConditions: customModalProps?.termsAndConditions,
    successTitle: REGISTERING_INTEREST_SUCCESS_TITLE,
    successSubtitle: REGISTERING_INTEREST_SUCCESS_SUBTITLE,
    isOpen,
    onClose,
    errorTitle: JOIN_OUR_MAILING_LIST_ERROR,
    onSubmit: async (formValues: InquireData) => {
      const observer = captchaInitObserver()
      await recaptchaRef?.current?.executeAsync()
      removeCaptchaObserver(observer)

      const inquiryPayload: RegisteredInterestData = {
        ...formValues,
        firstName: formValues.firstName || '',
        lastName: formValues.lastName,
        comments: formValues.comments,

        id: createRandomUUID(),
        currentUrl: window.location.href,
        timestamp: getNewYorkTimestamp(),
        formId: FORM_ID_INQUIRY,
        ctaText: customModalProps?.primaryCTA?.text,
        inquiryType,
        inquiryCategory:
          customModalProps?.inquiryCategory || INQUIRY_CATEGORIES.REGISTERED_INTEREST,
        access: formValues.access,
        news: formValues.news,
        events: formValues.events,
        pageTitle: document.title || '',
        tags: customModalProps?.tags,
        landingPageURL,
      }

      return sendRegisteredInterestRequest(inquiryPayload)
        .then((result) => ({
          error: result?.error,
        }))
        .then((result) => {
          const isSuccess = !result?.error
          if (isSuccess) {
            gtmRegisteredInterestSubmittedEvent(formValues)
          } else {
            GTMErrorMessageEvent({
              error_message: result.error,
              type: ErrorType.FORM,
            })
          }
          return { isSuccess, error: result?.error }
        })
        .catch((error) => {
          GTMErrorMessageEvent({
            error_message: error?.error?.message || error,
            type: ErrorType.FORM,
          })
          return { isSuccess: false, error }
        })
    },
    tags: customModalProps?.tags,
    onDirty,
    disableBackdrop,
    recaptchaNode: usePortalForRecaptcha ? (
      createPortal(<RecaptchaNode recaptchaRef={recaptchaRef} />, document.body)
    ) : (
      <RecaptchaNode recaptchaRef={recaptchaRef} />
    ),
    image: customModalProps?.image || null,
    primaryCTA: customModalProps?.primaryCTA || null,
  }

  const openRegisteredInterestModal = (
    modalProps: RegisteredInterestModalProps,
    triggerType: ModalTriggerTypes
  ) => {
    setIsOpen(true)
    if (modalProps) {
      setCustomModalProps(modalProps)
    }
    setTriggerType(triggerType)
  }

  return {
    registeredInterestFormProps,
    openRegisteredInterestModal,
  }
}
