import * as Sentry from '@sentry/nextjs'
import { groq } from 'next-sanity'
import { z } from 'zod'

import { applyExcludeFilter } from '@/common/components/popups/popupsUtils'
import type { PortableTextElementProps } from '@/common/utilsMappers/safe'
import { getClient } from '@/sanity/client'
import { mediaBuilder, MediaBuilderSchema } from '@/sanity/queries/components/builders/mediaBuilder'
import {
  basicContentWithLinks,
  BasicContentWithLinksSchema,
} from '@/sanity/queries/components/portableText/basicContentWithLinks'
import { getCookie } from '@/utils/cookies/getCookie'

import type { PageSectionsKey, PageSectionsValues } from './constants'
import { LandingTypeToURL, PageSections } from './constants'
import type { ExhibitionsDateFilterType } from './filterSection'
import { filterSectionsFields, FilterSectionsSchema } from './filterSection'

const triggerFields = groq`
    timeBased,
    timeBased == true => {
      triggerTime
    },
  `

const TriggerFieldsSchema = z.object({
  timeBased: z.boolean(),
  triggerTime: z.number().nullish(),
})

const popupFields = groq`
  type,
  name,
  displayAlways,
  _key,
  filterSections[]{
    ${filterSectionsFields}
  },
  termsAndConditions->{
    content[] {
      ${basicContentWithLinks}
    },
    name
  },
  triggers{
    ${triggerFields}
  },
  tags,
  type == "newsletter" || type == "customPromo" => {
    title,
    description,
    type == "newsletter" => {
      'primaryCTA': {
        'action': 'newsletter',
        'text': ctaText
      }
    },
    type == "customPromo" => {
      primaryCTA {
        ...,
        action == "Link Content" => {
          'link': {
            'href': linkedContent->slug.current,
            'blank': false
          }
        }
      }
    },
    media {
      ${mediaBuilder}
    },
  },
  type == "customPromo" => {
    submissionCTA,
  },
`

const commonPopupFields = z.object({
  _key: z.string(),
  name: z.string().nullish(),
  displayAlways: z.nullable(z.boolean()),
  termsAndConditions: z
    .object({
      name: z.string().nullable(),
      content: z.nullable(z.array(BasicContentWithLinksSchema)),
    })
    .nullable(),
  filterSections: FilterSectionsSchema,
  triggers: TriggerFieldsSchema,
  tags: z.array(z.string()).nullish(),
})
const newsletterCustomPromoFields = z.object({
  title: z.string().nullish(),
  description: z.any().nullish(),
  primaryCTA: z.nullable(z.any()),
  media: MediaBuilderSchema.nullish(),
})
type CustomNewsletterPopupFieldsType = z.infer<typeof newsletterCustomPromoFields>

const customPromoFields = z.object({
  submissionCTA: z.any().nullish(),
})

const PopupItemSchema = z.discriminatedUnion('type', [
  commonPopupFields.merge(newsletterCustomPromoFields).extend({ type: z.literal('newsletter') }),
  commonPopupFields.extend({ type: z.literal('inquire') }),
  commonPopupFields
    .merge(newsletterCustomPromoFields)
    .merge(customPromoFields)
    .extend({ type: z.literal('customPromo') }),
])

type PopupItemType = z.infer<typeof PopupItemSchema>

const allCampaigns = groq`
*[_type == "campaign"]{
    _id,
    title,
    orderRank,
    cookieDaysToExpire,
    popupsList[]{
        ${popupFields}
    },
}|order(orderRank)`

const CampaignSchema = z.object({
  _id: z.string(),
  title: z.string(),
  cookieDaysToExpire: z.number(),
  popupsList: z.array(PopupItemSchema).nullable(),
})
const CampaignsSchema = z.array(CampaignSchema)

type CampaignType = z.infer<typeof CampaignSchema>
type CampaignsType = z.infer<typeof CampaignsSchema>

export type PopUpInfo = {
  campaignId: CampaignType['_id']
  campaignName: CampaignType['title']
  daysToExpire: CampaignType['cookieDaysToExpire']
  description: CustomNewsletterPopupFieldsType['description']
  displayAlways: PopupItemType['displayAlways']
  id: PopupItemType['_key']
  popupName: PopupItemType['name']
  tags: PopupItemType['tags']
  openStatusFilter: ExhibitionsDateFilterType | null
  excludedSlugs: string[]
  termsAndConditionsProps: PortableTextElementProps
  title: CustomNewsletterPopupFieldsType['title']
  triggers: PopupItemType['triggers']
  type: PopupItemType['type']
  image: {
    src: string
    alt: string
  } | null
  primaryCTA: {
    text: string | null
    action?: string | null
    link?: {
      href: string | null
      blank?: boolean
    }
  } | null
}

export type FilterSectionsDictionary = Record<string, PopUpInfo>

type GetPopupPerPageArgs = {
  url: string
  pageType: string | undefined
  campaigns: FilterSectionsDictionary | null | undefined
  eventStatus: ExhibitionsDateFilterType | null
}

export const normalizeArgs = ({ url, pageType }: Pick<GetPopupPerPageArgs, 'url' | 'pageType'>) => {
  // url refers to a subpage of an artist page if it consists of more than 3 parts. Ex.: /artists/marlene-dumas/survey
  const isArtistsSubpagePage = url.startsWith('/artists/') ? url.split('/').length > 3 : false
  const unifiedUrl = isArtistsSubpagePage ? url.split('/').slice(0, -1).join('/') : url
  const unifiedUrlWithoutAnchor = unifiedUrl.split('#')[0] ?? ''
  const unifiedPageType = isArtistsSubpagePage ? PageSections['All Artist Pages'] : pageType
  return { unifiedUrlWithoutAnchor, unifiedPageType }
}

export const getPopupPerPage = ({
  url,
  pageType,
  campaigns,
  eventStatus,
}: GetPopupPerPageArgs): PopUpInfo | null => {
  if (!campaigns || !url) return null

  const { unifiedUrlWithoutAnchor, unifiedPageType } = normalizeArgs({ url, pageType })

  const pageSectionsKeys = Object.keys(PageSections) as PageSectionsKey[]
  const sectionKey = pageSectionsKeys.find((key) => PageSections[key] === unifiedPageType) ?? ''
  if (!campaigns[unifiedUrlWithoutAnchor] && !campaigns[sectionKey]) return null

  // pick record by url if exists (single page. Ex.: /artists/marlene-dumas)
  const popupDataByUrl = campaigns[unifiedUrlWithoutAnchor]
  if (popupDataByUrl) {
    // check that the popup is not already displayed
    const hasCookieByUrl = getCookie(popupDataByUrl.campaignName)
    if (hasCookieByUrl) return null
    if (
      eventStatus &&
      popupDataByUrl.openStatusFilter &&
      popupDataByUrl.openStatusFilter !== eventStatus
    ) {
      return null
    }
    return applyExcludeFilter(popupDataByUrl, unifiedUrlWithoutAnchor)
  }

  // pick record by section if exists (group of pages. Ex.: /artists/*)
  const popupDataBySectionKey = campaigns[sectionKey]

  if (popupDataBySectionKey) {
    // check that the popup is not already displayed
    const hasCookieBySection = getCookie(popupDataBySectionKey.campaignName)
    if (hasCookieBySection) return null
    if (
      eventStatus &&
      popupDataBySectionKey.openStatusFilter &&
      popupDataBySectionKey.openStatusFilter !== eventStatus
    ) {
      return null
    }
    return applyExcludeFilter(popupDataBySectionKey, unifiedUrlWithoutAnchor)
  }

  return null
}

type AddToDictionaryType = {
  url: string | undefined
  popup: PopUpInfo
  dictionary: FilterSectionsDictionary
}

const addPopupToDictionary = (
  { url, popup, dictionary }: AddToDictionaryType,
  { allPagesKey }: { allPagesKey?: string }
) => {
  // only add first occurrence

  if (url && !dictionary?.[url]) {
    dictionary[url] =
      // If an ALL section already exists, takes precedence
      allPagesKey && dictionary[allPagesKey] ? dictionary[allPagesKey] : popup
  }
}

/* This mapper filters campaign popups an return an object with the following shape: { [url]: {highest priority popup for the url} } */
const getPopupDictionary = (campaigns: CampaignsType): FilterSectionsDictionary => {
  return campaigns.reduce<FilterSectionsDictionary>((dictionary, campaign) => {
    const prevCopy = { ...dictionary }
    const { _id, title, popupsList = [], cookieDaysToExpire } = campaign ?? {}
    popupsList?.forEach((item) => {
      const {
        displayAlways = false,
        type: popUpType,
        name,
        triggers,
        termsAndConditions,
        filterSections = [],
        tags = [],
      } = item

      filterSections?.forEach((section) => {
        const { type, page, excludedSlugs, openStatusFilter } = section
        const { url, _type } = page ?? {}

        const sectionKey = Object.entries(PageSections).find(
          ([_, value]) => value === type
        )?.[0] as PageSectionsKey | undefined

        const isNewsOrPromo = item.type === 'newsletter' || item.type === 'customPromo'
        const popupInfo: PopUpInfo = {
          campaignId: _id,
          campaignName: title,
          popupName: name,
          type: popUpType,
          daysToExpire: cookieDaysToExpire,
          openStatusFilter,
          excludedSlugs: excludedSlugs.filter((slug) => slug !== null),
          displayAlways,
          termsAndConditionsProps: {
            charLimit: 250,
            text: termsAndConditions?.content ?? [],
            customStyles: {
              normal: 'text-black-60 !text-xs',
            },
            customProps: {
              internalPathLink: {
                shortUnderlineOffset: true,
              },
              link: {
                shortUnderlineOffset: true,
              },
            },
          },
          triggers,
          tags,
          image: null,
          primaryCTA: null,
          id: item._key,
          title: '',
          description: '',
        }

        if (isNewsOrPromo) {
          popupInfo.title = item.title
          popupInfo.description = item.description
          if (item.primaryCTA?.text) {
            popupInfo.primaryCTA = item.primaryCTA
          }
          if (item.media?.type === 'Image') {
            popupInfo.image = {
              src: item.media.image?.url,
              alt: item.media.alt ?? '',
            }
          }
        }

        let dictionaryKey
        let extras = {}

        // The target is a single page or a special page
        if (
          (type === PageSections['Single Page Record'] || type === PageSections['Special Pages']) &&
          url
        ) {
          dictionaryKey = url
          const indexOfAllPages = Object.values(PageSections).indexOf(_type as PageSectionsValues)
          const allPagesKey = Object.keys(PageSections)[indexOfAllPages]
          extras = { allPagesKey }
          // The target is a hardcoded page
        } else if (type && LandingTypeToURL[type as keyof typeof LandingTypeToURL]) {
          dictionaryKey = LandingTypeToURL[type as keyof typeof LandingTypeToURL]
          // The target is a custom url
        } else if (type === 'customSlug' && url) {
          dictionaryKey = url
          // The target is a section
        } else if (sectionKey) {
          dictionaryKey = sectionKey
        }
        addPopupToDictionary(
          {
            url: dictionaryKey,
            popup: popupInfo,
            dictionary: prevCopy,
          },
          extras
        )
      })
    })
    return prevCopy
  }, {})
}

// called server-side to get all campaigns
export async function getAllCampaigns(): Promise<FilterSectionsDictionary> {
  // TODO add slug to client on popups call
  const { client } = getClient({
    draftMode: false,
    draftViewToken: '',
    slug: '',
    originName: 'service-getAllCampaigns',
    filePath: __filename,
  })
  const data = await client.fetch(allCampaigns)
  const validatedData = CampaignsSchema.safeParse(data)
  if (!validatedData.success) {
    Sentry.captureException(validatedData.error)
    return {}
  }
  return getPopupDictionary(validatedData.data)
}
