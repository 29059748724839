type ShopifyLoader = (client: () => void) => void
type ShopifyCleaner = () => void

const isBotRegex = new RegExp('bot|googlebot|crawler|spider|robot|crawling', 'i')
const SHOPIFY_TIMEOUT = 10000
const CHECK_INTERVAL = 100
const MAX_RETRIES = 3

// Check if the shopify library is available
const isShopifyAvailable = (): boolean => {
  return typeof window.ShopifyBuy !== 'undefined' || typeof window.UpdatedShopifyBuy !== 'undefined'
}

// Wait for the shopify library to become available
const waitForShopify = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (isShopifyAvailable()) {
        clearInterval(interval)
        resolve()
      }
    }, CHECK_INTERVAL)

    setTimeout(() => {
      clearInterval(interval)
      reject(new Error('Timeout waiting for Shopify'))
    }, SHOPIFY_TIMEOUT)
  })
}

export const SCRIPT_ID = 'shopify-buy-button'
export const loadShopify: ShopifyLoader = (initClient) => {
  const userAgent = navigator.userAgent
  const isBot = isBotRegex.test(userAgent) || !userAgent
  if (isBot) {
    console.info('Shopify script not loaded because of user agent.')
    console.info('User agent:', userAgent)
    return
  }
  const shopifyScriptElement = document.getElementById(SCRIPT_ID)
  if (!shopifyScriptElement) initClient()
}

export const clearShopify: ShopifyCleaner = () => {
  const script = document.getElementById(SCRIPT_ID)
  if (script) script.remove()
}

export const loadScriptToDOM = async (script: HTMLScriptElement) => {
  const loadScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      script.onload = () => resolve()
      script.onerror = () => reject(new Error('Failed to load Shopify script'))
      document.body.appendChild(script)
    })
  }

  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      await loadScript()
      await waitForShopify()
      break
    } catch (error) {
      if (i === MAX_RETRIES - 1) throw error
      console.warn(`Attempt ${i + 1} failed, retrying...`)
      document.body.removeChild(script)
    }
  }
}
