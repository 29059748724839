import { groq } from 'next-sanity'
import { z } from 'zod'

import { CTAActionTypes } from '@/sanity/types'

import { file, FileSchema } from '../../primitives/file.queries'

export const ctaBuilder = groq`
    _type,
    "action": coalesce(action, "${CTAActionTypes.NONE}"),
    text,
    action == "${CTAActionTypes.LINK}" => {
        link {
          blank,
          href,
        },
    },
    action == "${CTAActionTypes.DOWNLOAD_PDF}" => {
        file {
          ${file}
        },
    },
    action == "${CTAActionTypes.ECOMM}" => {},
    action == "${CTAActionTypes.NEWSLETTER}" => {},
    action == "${CTAActionTypes.REGISTERED_INTEREST}" => {},
    action == "${CTAActionTypes.LINK_CONTENT}" => {
      'url': linkedContent->slug.current,
      'link': {
        'blank': false,
        'href': linkedContent->slug.current,
      },
    },
`

export const LinkSchema = z.object({
  blank: z.nullable(z.boolean()),
  href: z.string().nullish(),
})

export const CTABuilderCommonSchema = z.object({
  _type: z.nullable(z.string()),
  text: z.nullable(z.string()),
})

export const LinkCTASchema = z
  .object({
    action: z.literal(CTAActionTypes.LINK),
    link: z.nullable(LinkSchema),
  })
  .merge(CTABuilderCommonSchema)

export const LinkContentCTASchema = z
  .object({
    url: LinkSchema.shape.href,
    action: z.literal(CTAActionTypes.LINK_CONTENT),
    link: z.nullable(LinkSchema),
  })
  .merge(CTABuilderCommonSchema)

export const NoneCTASchema = z
  .object({
    action: z.literal(CTAActionTypes.NONE),
    link: LinkSchema.nullish(),
  })
  .merge(CTABuilderCommonSchema)

export const DownloadPDFCTASchema = z
  .object({
    action: z.literal(CTAActionTypes.DOWNLOAD_PDF),
    file: z.nullable(FileSchema),
  })
  .merge(CTABuilderCommonSchema)

export const CTABuilderSchema = z.discriminatedUnion('action', [
  LinkCTASchema,
  LinkContentCTASchema,
  DownloadPDFCTASchema,
  CTABuilderCommonSchema.extend({
    action: z.enum([
      CTAActionTypes.ECOMM,
      CTAActionTypes.NEWSLETTER,
      CTAActionTypes.REGISTERED_INTEREST,
      CTAActionTypes.INQUIRE,
      CTAActionTypes.NONE,
      CTAActionTypes.SOLD_OUT,
      CTAActionTypes.REQUEST_A_PREVIEW,
    ]),
  }),
])

export type NoneCTAType = z.infer<typeof NoneCTASchema>

export type LinkType = z.infer<typeof LinkSchema>

export type LinkCTAType = z.infer<typeof LinkCTASchema>

export type CTABuilderType = z.infer<typeof CTABuilderSchema>
