import { env } from '@/env'

const GTM_ID = env.NEXT_PUBLIC_GTM_ID

export const GTMNoScript = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display: none; visibility: hidden;" />
` as const

// Page Load Events
export const GTMPageLoadStartedText = {
  event: 'page_load_started',
  detailed_event: 'Page Load Started',
  page_data: {
    country: 'US',
  },
} as const

export const GTMPageLoadCompletedText = {
  event: 'page_view',
  detailed_event: 'Page Load Completed',
} as const

// Consignment Events
export const GTMConsignmentFormText = {
  event_data: {
    name: 'Consignment Form',
    type: 'Consignments',
  },
} as const

export const GTMConsignmentFormViewText = {
  event: 'consignment_view',
  detailed_event: 'Consignment Form Views',
  GTMConsignmentFormText,
} as const

export const GTMConsignmentFormStartText = {
  event: 'consignment_start',
  detailed_event: 'Consignment Start',
  GTMConsignmentFormText,
} as const

export const GTMConsignmentFormStepText = {
  event: 'consignment_step',
  detailed_event: 'Consignment Step Reached',
  GTMConsignmentFormText,
} as const

export const GTMConsignmentFormSubmitText = {
  event: 'consignment_submit',
  detailed_event: 'Consignment Submit',
  GTMConsignmentFormText,
} as const

// Click Link Events
export const GTMDownloadLinkText = {
  event: 'file_download',
  detailed_event: 'Download Link Clicked',
} as const

export const GTMExternalLinkText = {
  event: 'outbound_click',
  detailed_event: 'Exit Link Clicked',
} as const

// Scroll Depth Events

export const GTMScrollDepths = {
  event: 'gtm.scrollDepth',
  detailed_event: 'Scroll Depth',
} as const

// Error Message Event
export const GTMErrorMessageText = {
  event: 'site_error',
  detailed_event: 'Error Message Presented',
} as const

// Form Events
export const GTMFormSubmitText = {
  event: 'form_complete',
  detailed_event: 'Form Submission Succeeded',
} as const

export const GTMFormViewText = {
  event: 'form_view',
  detailed_event: 'Form Viewed',
} as const

export const GTMFormStartText = {
  event: 'form_start',
  detailed_event: 'Form Started',
} as const

export const GTMFormStepText = {
  event: 'form_step',
  detailed_event: 'Form Step Reached',
} as const

// Inquiry Form Events
export const GTMInquiryFormStartText = {
  event: 'inquiry_start',
  detailed_event: 'Inquiry Form Started',
} as const

export const GTMInquiryFormSubmitText = {
  event: 'inquiry_submit',
  detailed_event: 'Inquiry Form Submit',
} as const

export const GTMInquiryFormViewText = {
  event: 'inquiry_view',
  detailed_event: 'Inquiry Form Viewed',
} as const

// Request Preview Form Events
export const GTMRequestPreviewFormStartText = {
  event: 'request_preview_start',
  detailed_event: 'Request Preview Form Started',
}

export const GTMRequestPreviewFormSubmitText = {
  event: 'request_preview_submit',
  detailed_event: 'Request Preview Form Submit',
}

export const GTMRequestPreviewFormViewText = {
  event: 'request_preview_view',
  detailed_event: 'Request Preview Form Viewed',
}

// Popup Events
export const GTMPopupViewText = {
  event: 'view_popup',
  detailed_event: 'Popup Viewed',
} as const

export const GTMPopupClickText = {
  event: 'popup_click',
  detailed_event: 'Popup Clicked',
} as const

// Search and Filter Events
export const GTMOnsiteSearchPerformedText = {
  event: 'search',
  detailed_event: 'Onsite Search Pe as constrformed',
}

export const GTMOnsiteSearchResultsClickText = {
  event: 'select_search_result',
  detailed_event: 'Onsite Search Results Clicked',
} as const

export const GTMListingFilterAddText = {
  event: 'filter_add',
  detailed_event: 'Listing Filter Added',
} as const

// Podcast Events
export const GTMPodcastStartText = {
  event: 'podcast_start',
  detailed_event: 'Podcast Start',
} as const

export const GTMPodcastProgressText = {
  event: 'podcast_progress',
  detailed_event: 'Podcast Progress',
} as const

export const GTMPodcastCompleteText = {
  event: 'podcast_complete',
  detailed_event: 'Podcast Complete',
} as const

// Product Events
export const GTMProductListingViewedText = {
  event: 'view_item_list',
  detailed_event: 'Product Listing Displayed',
} as const

export const GTMProductListingClickedText = {
  event: 'select_item',
  detailed_event: 'Product Listing Item Clicked',
} as const

export const GTMProductViewText = {
  event: 'view_item',
  detailed_event: 'Product Viewed',
} as const

export const GTMProductAddToCartText = {
  event: 'add_to_cart',
  detailed_event: 'Product Added to Cart',
} as const

export const GTMProductRemoveFromCartText = {
  event: 'remove_from_cart',
  detailed_event: 'Product Removed from Cart',
}

// Social Share Events
export const GTMSocialShareText = {
  event: 'share',
  detailed_event: 'Social Contend Shared',
}

// User Events
export const GTMUserRegistrationText = {
  event: 'sign_up',
  detailed_event: 'User Registered',
}

export const GTMUserSignInText = {
  event: 'login',
  detailed_event: 'User Signed In',
}

export const GTMUserSignOutText = {
  event: 'logout',
  detailed_event: 'User Signed Out',
}

export const GTMDarkModeSwitchText = {
  event: 'dark_light_mode',
  detailed_event: 'Dark Ligth Mode Switched',
}

// User Subscription/Newsletter/EmailGate Events
export const GTMUserSubscriptionFormViewText = {
  event: 'subscribe_view',
  detailed_event: 'User Subscription Form View',
} as const

export const GTMUserSubscriptionFormStartedText = {
  event: 'subscribe_start',
  detailed_event: 'User Subscription Started',
} as const

export const GTMUserSubscriptionText = {
  event: 'subscribe',
  detailed_event: 'User Subscribed',
} as const

// Registered Interest Form Events
export const GTMRegisteredInterestFormViewText = {
  event: 'registered_interest_view',
  detailed_event: 'Registered Interest Form Viewed',
} as const

export const GTMRegisteredInterestFormStartedText = {
  event: 'registered_interest_start',
  detailed_event: 'Registered Interest Form Started',
} as const

export const GTMRegisteredInterestFormSubmittedText = {
  event: 'registered_interest_submit',
  detailed_event: 'Registered Interest Form Submitted',
} as const
