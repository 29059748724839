// 'Section' is a collection of pages e.g [artists detail pages, artists landing, ...]

export const PageSections = {
  'All Articles': 'article',
  'All Artist Pages': 'artistPage',
  'All Exhibition Pages': 'exhibitionPage',
  'All Exceptional Works': 'exceptionalWork',
  'All Fairs': 'fairPage',
  'All Online Exhibitions': 'onlineExhibitionPage',
  //
  'Landing Page: Artist Listing': 'artistListing',
  'Landing Page: Available Artworks': 'availableArtworks',
  'Landing Page: Exhibitions Landing': 'exhibitionsLanding',
  'Landing Page: Exhibitions Past': 'exhibitionsPast',
  'Landing Page: Home Page': 'home',
  'Landing Page: Hong Kong': 'hongkong',
  //
  'Custom Slug': 'customSlug',
  'Single Page Record': 'singlePageRecord',
  'Special Pages': 'page',
} as const

export type PageSectionsKey = keyof typeof PageSections
export type PageSectionsValues = (typeof PageSections)[keyof typeof PageSections]

export const LandingTypeToURL = {
  availableArtworks: '/available-artworks',
  artistListing: '/artists',
  exhibitionsLanding: '/exhibitions',
  exhibitionsPast: '/exhibitions/past-exhibitions',
  home: '/',
  hongkong: '/hongkong',
} as const
