import { useCallback, useEffect } from 'react'

import type { PopUpInfo } from '@/common/api/campaigns/campaigns'
import type { NewsletterModalProps } from '@/common/components/popups/modalTriggerListener/useNewsletterFormModal'
import { useNewsletterFormModal } from '@/common/components/popups/modalTriggerListener/useNewsletterFormModal'
import type { PromoModalProps } from '@/common/components/popups/modalTriggerListener/usePromoModal'
import { usePromoModal } from '@/common/components/popups/modalTriggerListener/usePromoModal'
import type { RegisteredInterestModalProps } from '@/common/components/popups/modalTriggerListener/useRegisteredInterestFormModal'
import { useRegisteredInterestFormModal } from '@/common/components/popups/modalTriggerListener/useRegisteredInterestFormModal'
import { NEWSLETTER, REGISTERED_INTEREST, SIGN_UP } from '@/common/constants/commonCopies'
import { gtmInquiryFormViewEvent } from '@/common/utils/gtm/gtmInquiryFormEvent'
import { gtmPopupViewedEvent, TypeTypes } from '@/common/utils/gtm/gtmPopupEvent'
import { gtmRequestPreviewFormViewEvent } from '@/common/utils/gtm/gtmRequestPreviewFormEvent'
import type {
  InquiryPopupEventDetails,
  RegisteredInterestPopupEventDetails,
} from '@/common/utilsMappers/cta.mapper'
import { DzPromoModal } from '@/components/forms/DzPromoModal'
import { NewsletterFormModal } from '@/components/forms/newsletterFormModal'
import { RecaptchaInquireFormModal } from '@/components/forms/recaptchaInquireFormModal'
import { RegisteredInterestFormModal } from '@/components/forms/registeredInterestFormModal'
import useGtmNewsletterEvent from '@/components/hooks/gtm/useGtmNewsletterEvent'
import useGtmRegisteredInterestEvent from '@/components/hooks/gtm/useGtmRegisteredInterestEvent'
import { useHashRoutedInquiryModal } from '@/components/hooks/useHashRoutedInquiryModal'
import type { InquireModalProps } from '@/components/hooks/useOpenInquiryDispatch'
import { createInquireModalGeneralProps } from '@/components/hooks/useOpenInquiryDispatch'
import { EVENT_TRIGGER_MODAL, MethodTypes, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { ModalTypes } from '@/sanity/types'
import { setCookie } from '@/utils/cookies/setCookie'

import type { NewsletterPopupEventDetails, PromoPopupEventDetails } from '../utils'

const DISPLAY_ALWAYS_SESSION_LENGTH_MINUTES = 1440 // 1 day

type ModalTriggerListenerProps = { usePortalForRecaptcha?: boolean }

/**
 * Component that listens for modal trigger events and renders the appropriate modals.
 *
 * @component
 * @param {boolean} usePortalForRecaptcha - Needed if you render the reCAPTCHA components in a shadow DOM. It will render the reCAPTCHA in the document body.
 */
export const ModalTriggerListener = ({ usePortalForRecaptcha }: ModalTriggerListenerProps) => {
  // props for modals
  const inquireModalProps = useHashRoutedInquiryModal({ usePortalForRecaptcha })
  const openInquireModal = inquireModalProps.openInquireModal
  const requestPreviewModalProps = useHashRoutedInquiryModal({
    type: ModalTypes.REQUEST_A_PREVIEW,
    usePortalForRecaptcha,
  })
  const openRequestPreviewModal = requestPreviewModalProps.openInquireModal
  const { newsletterFormProps, openNewsletterModal } = useNewsletterFormModal({
    usePortalForRecaptcha,
  })
  const { registeredInterestFormProps, openRegisteredInterestModal } =
    useRegisteredInterestFormModal({ usePortalForRecaptcha })
  const { promoModalProps, openPromoModal } = usePromoModal()
  // least calculations
  const generalInquireProps = createInquireModalGeneralProps()
  const { gtmNewsletterSubscriptionViewEvent } = useGtmNewsletterEvent()
  const { gtmRegisteredInterestViewEvent } = useGtmRegisteredInterestEvent()

  const isModalOpen =
    inquireModalProps.isOpen ||
    requestPreviewModalProps.isOpen ||
    newsletterFormProps.isOpen ||
    registeredInterestFormProps.isOpen ||
    promoModalProps?.isOpen

  const setPopupCookie = (popupInfo?: PopUpInfo) => {
    if (popupInfo) {
      const expirations = popupInfo.displayAlways
        ? // If the popup is set to display always, set 5h cookie that is expected average session length
          { minutesToExpire: DISPLAY_ALWAYS_SESSION_LENGTH_MINUTES }
        : // If the popup is not set to display always, set declared daysToExpire amount of days
          { daysToExpire: popupInfo.daysToExpire }

      setCookie(
        popupInfo.campaignName,
        { campaignId: popupInfo.campaignId, popupId: popupInfo.id },
        {},
        expirations
      )
    }
  }

  type PopupEvent = CustomEvent<
    | NewsletterPopupEventDetails
    | PromoPopupEventDetails
    | InquiryPopupEventDetails
    | RegisteredInterestPopupEventDetails
  >
  const modalTriggerListener = useCallback(
    (event: PopupEvent) => {
      const { detail } = event ?? {}
      const { modalType, props, triggerType, popupInfo } = detail ?? {}

      if (isModalOpen || !modalType) return

      if (isModalNewsletters(modalType, props)) {
        openNewsletterModal(props, triggerType, { useAnchor: props?.useAnchor })
        gtmNewsletterSubscriptionViewEvent({
          // we have cta_value, and it might be used here instead of the rare
          cta_value: props?.ctaText ?? NEWSLETTER,
          method: triggerType === ModalTriggerTypes.POPUP ? MethodTypes.POPUP : props?.method,
        })
        if (triggerType === ModalTriggerTypes.POPUP) {
          gtmPopupViewedEvent({
            cta_value: SIGN_UP,
            method: MethodTypes.CENTER,
            type: TypeTypes.FORM,
          })
        }
        setPopupCookie(popupInfo)
      }

      if (isModalRegisteredInterest(modalType, props)) {
        openRegisteredInterestModal(props, triggerType!)
        gtmRegisteredInterestViewEvent({
          cta_value: props?.ctaText ?? props?.primaryCTA?.text ?? REGISTERED_INTEREST,
          method: 'interstitial',
        })
        setPopupCookie(popupInfo)
      }

      if (isModalInquire(modalType, props)) {
        const inquireModalProps = props || generalInquireProps
        const { useAnchor } = props ?? {}
        openInquireModal({ inquireModalProps, options: { useAnchor } })
        gtmInquiryFormViewEvent(inquireModalProps)
        setPopupCookie(popupInfo)
      }

      if (isModalRequestPreview(modalType, props)) {
        const requestPreviewModalProps = props || generalInquireProps
        const { useAnchor } = props ?? {}
        openRequestPreviewModal({
          inquireModalProps: requestPreviewModalProps,
          options: { useAnchor },
        })
        gtmRequestPreviewFormViewEvent(requestPreviewModalProps)
        setPopupCookie(popupInfo)
      }

      if (isModalPromo(modalType, props)) {
        openPromoModal(props)
        gtmPopupViewedEvent({
          cta_value: props.linkText,
          method: MethodTypes.CENTER,
          type: TypeTypes.NON_FORM,
        })
        setPopupCookie(popupInfo)
      }
    },
    [
      generalInquireProps,
      gtmNewsletterSubscriptionViewEvent,
      gtmRegisteredInterestViewEvent,
      isModalOpen,
      openInquireModal,
      openNewsletterModal,
      openRequestPreviewModal,
      openRegisteredInterestModal,
      openPromoModal,
    ]
  )

  useEffect(() => {
    if (typeof window !== undefined) {
      window.document.removeEventListener(
        EVENT_TRIGGER_MODAL,
        modalTriggerListener as EventListener
      )
      window.document.addEventListener(EVENT_TRIGGER_MODAL, modalTriggerListener as EventListener)
    }

    return () => {
      if (typeof window !== undefined) {
        window.document.removeEventListener(
          EVENT_TRIGGER_MODAL,
          modalTriggerListener as EventListener
        )
      }
    }
  }, [modalTriggerListener])
  return (
    <>
      {newsletterFormProps && newsletterFormProps.isOpen && (
        // @ts-expect-error: check props
        <NewsletterFormModal {...newsletterFormProps} />
      )}
      {registeredInterestFormProps?.isOpen && (
        // @ts-expect-error: check props
        <RegisteredInterestFormModal {...registeredInterestFormProps} />
      )}
      {inquireModalProps && inquireModalProps.isOpen && (
        <RecaptchaInquireFormModal {...inquireModalProps} />
      )}
      {requestPreviewModalProps && requestPreviewModalProps.isOpen && (
        <RecaptchaInquireFormModal {...requestPreviewModalProps} />
      )}
      {promoModalProps && promoModalProps.isOpen ? <DzPromoModal {...promoModalProps} /> : null}
    </>
  )
}

function isModalNewsletters(
  modalType: ModalTypes,
  _props: unknown
): _props is NonNullable<NewsletterModalProps> {
  return modalType === ModalTypes.NEWSLETTER
}

function isModalRegisteredInterest(
  modalType: ModalTypes,
  _props: unknown
): _props is NonNullable<RegisteredInterestModalProps> {
  return modalType === ModalTypes.REGISTERED_INTEREST
}

function isModalInquire(
  modalType: ModalTypes,
  _props: unknown
): _props is NonNullable<InquireModalProps> {
  return modalType === ModalTypes.INQUIRE
}

function isModalRequestPreview(
  modalType: ModalTypes,
  _props: unknown
): _props is NonNullable<InquireModalProps> {
  return modalType === ModalTypes.REQUEST_A_PREVIEW
}

function isModalPromo(
  modalType: ModalTypes,
  _props: unknown
): _props is NonNullable<PromoModalProps> {
  return modalType === ModalTypes.PROMO
}
