import { captureException } from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import type { NextRouter } from 'next/router'
import { memo, type RefObject } from 'react'
import useSWRImmutable from 'swr/immutable'

import type { CampaignsCache } from '@/common/api/campaigns'
import { getPopupPerPage } from '@/common/api/campaigns/campaigns'
import { useImagePreload } from '@/components/hooks/useImagePreload'
import { useTimeDelayedRoutePopup } from '@/components/hooks/useTimeDelayedRoutePopup'
import { isLighthouseAnalyticsEnabled } from '@/featureFlag'
import { useIsEmailExpired } from '@/store/emailGateStore'

import { getEmailGateForPage } from './EmailGate/getEmailGateForPage'
import type { EventPeriod } from './getEventStatus'
import { getEventStatus } from './getEventStatus'
import { ModalTriggerListener } from './modalTriggerListener/modalTriggerListener'
import { usePageMetadata } from './PageMetaDataContext'
import { getShouldShowEmailGate, getShouldShowPopup, isCampaignCollectEmail } from './popupsUtils'
import { openPopupCb } from './utils'

export const bypassEmailGateKey = 'authkey'

type PopUpsProps = {
  router: NextRouter
  pageType?: string
  usePortalForRecaptcha?: boolean
  shadowRootRef?: RefObject<HTMLElement>
}
const PopUps = ({ router, pageType, usePortalForRecaptcha, shadowRootRef }: PopUpsProps) => {
  const EmailGate = dynamic(() => import('./EmailGate'), { ssr: false })
  const url = router.asPath
  const bypassEmailGate = router.query?.[bypassEmailGateKey] as string | undefined
  const isEmailExpired = useIsEmailExpired()

  const pageContext = usePageMetadata<EventPeriod>()
  const eventStatus = getEventStatus(pageContext)

  const response = useSWRImmutable<CampaignsCache | null>('campaigns', fetchCampaigns)
  const { campaigns, emailGates } = response.data ?? {}

  const popupForPage = getPopupPerPage({ url, pageType, campaigns, eventStatus })
  const emailGateForPage = getEmailGateForPage({ url, pageType, emailGates, eventStatus })

  const shouldShowEmailGate = getShouldShowEmailGate({
    isEmailGateAssigned: emailGateForPage !== null,
    isEmailExpired,
    isBypassEmailGate: bypassEmailGate !== undefined,
  })
  const emailGate = shouldShowEmailGate ? emailGateForPage : null

  const shouldShowPopup = getShouldShowPopup({
    shouldShowEmailGate,
    isEmailExpired,
    isPopupAssigned: popupForPage !== null,
    isPopupCollectEmail: isCampaignCollectEmail(popupForPage),
  })

  useTimeDelayedRoutePopup(() => {
    if (shouldShowPopup) return popupForPage ? openPopupCb(popupForPage) : null
    return null
  }, popupForPage?.triggers.triggerTime)

  useImagePreload(shouldShowPopup ? popupForPage?.image?.src : undefined)

  if (isLighthouseAnalyticsEnabled) return null

  return (
    <>
      <ModalTriggerListener usePortalForRecaptcha={usePortalForRecaptcha} />
      <EmailGate emailGate={emailGate} shadowRootRef={shadowRootRef} />
    </>
  )
}

const MemoizedPopUps = memo(PopUps)

export { MemoizedPopUps as PopUps }

const campaignEndpoint = '/api/campaigns'
async function fetchCampaigns() {
  try {
    const res = await fetch(campaignEndpoint)
    return res.json()
  } catch (error) {
    captureException(error, {
      extra: {
        customContext: 'fetchCampaigns',
        additionalInfo: 'Failed to fetch campaigns',
      },
    })
    return null
  }
}
