'use client'

import dynamic from 'next/dynamic'

import { env } from '@/env'
import { isBrazeEnabled } from '@/featureFlag'

const BrazeWrapper = () => {
  const { sdk: isSdkEnabled } = isBrazeEnabled
  if (!isSdkEnabled) return null

  const Braze = dynamic(() => import('@/common/components/braze/Braze'), { ssr: false })
  const apiKey = env.NEXT_PUBLIC_BRAZE_SDK_KEY
  const baseUrl = env.NEXT_PUBLIC_BRAZE_ENDPOINT
  const enableLogging = env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
  const noCookies = true // TODO: 2024-07-09: We need to decide on user consent before setting cookies

  return (
    <Braze apiKey={apiKey} baseUrl={baseUrl} enableLogging={enableLogging} noCookies={noCookies} />
  )
}

export default BrazeWrapper
