import { normalizeArgs } from '@/common/api/campaigns/campaigns'
import { LandingTypeToURL } from '@/common/api/campaigns/constants'
import type { EmailGateData, EmailGateDataDictionary } from '@/common/api/campaigns/emailGates'
import type { ExhibitionsDateFilterType } from '@/common/api/campaigns/filterSection'

import { applyExcludeFilter } from '../popupsUtils'

type GetEmailGateForPageArgs = {
  url: string
  pageType: string | undefined
  emailGates: EmailGateDataDictionary | null | undefined
  eventStatus: ExhibitionsDateFilterType | null
}
export const getEmailGateForPage = ({
  url,
  pageType,
  emailGates,
  eventStatus,
}: GetEmailGateForPageArgs): EmailGateData | null => {
  if (!emailGates || !url) return null

  const { unifiedUrlWithoutAnchor, unifiedPageType } = normalizeArgs({ url, pageType })

  // Home page
  if (unifiedUrlWithoutAnchor === LandingTypeToURL.home) {
    if (!emailGates['/']) return null
    return applyExcludeFilter(emailGates['/'], unifiedUrlWithoutAnchor)
  }

  // Url with nested sections
  const key = Object.keys(emailGates)
    .filter((key) => key !== '/') // Exclude home page case
    .find((key) => unifiedUrlWithoutAnchor.startsWith(key))

  const emailGateByUrl = key ? emailGates[key] : null

  if (emailGateByUrl) {
    return applyExcludeFilter(emailGateByUrl, unifiedUrlWithoutAnchor)
  }

  const emailGateBySectionKey = unifiedPageType ? emailGates[unifiedPageType] : null

  if (!emailGateBySectionKey) return null
  if (
    eventStatus &&
    emailGateBySectionKey.openStatusFilter &&
    emailGateBySectionKey.openStatusFilter !== eventStatus
  ) {
    return null
  }

  return applyExcludeFilter(emailGateBySectionKey, unifiedUrlWithoutAnchor)
}
