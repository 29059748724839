import type { PopUpInfo } from '@/common/api/campaigns/campaigns'
import { SUBSCRIBE_METHOD } from '@/common/constants/subscribe'
import { createPortableTextElement, safeText } from '@/common/utilsMappers/safe'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { ModalTypes } from '@/sanity/types'

import type { NewsletterModalProps } from './modalTriggerListener/useNewsletterFormModal'
import type { PromoModalProps } from './modalTriggerListener/usePromoModal'

export type NewsletterPopupEventDetails = {
  modalType: ModalTypes.NEWSLETTER
  props: NewsletterModalProps
} & (
  | {
      popupInfo: PopUpInfo
      triggerType: ModalTriggerTypes.POPUP
    }
  | {
      triggerType: ModalTriggerTypes.CTA
      popupInfo?: never
    }
)

export type PromoPopupEventDetails = {
  modalType: ModalTypes.PROMO
  popupInfo: PopUpInfo
  props: PromoModalProps
  triggerType: ModalTriggerTypes.POPUP
}

export const openPopupCb = (data?: PopUpInfo) => {
  if (!data) return

  const subtitleText = safeText({ key: 'subtitle', text: data.description })

  if (data.type === 'newsletter') {
    const parsedData = {
      ...subtitleText,
      title: data.title,
      image: data.image,
      cta_value: data.primaryCTA?.text,
      primaryCTA: data.primaryCTA,
      tags: data.tags,
      termsAndConditions: createPortableTextElement(data.termsAndConditionsProps),
    }
    window.document.dispatchEvent(
      ModalTriggerEvent({
        modalType: ModalTypes.NEWSLETTER,
        props: parsedData,
        triggerType: ModalTriggerTypes.POPUP,
        popupInfo: data,
      } satisfies NewsletterPopupEventDetails)
    )
    return
  }
  if (data.type === 'customPromo') {
    const href = data.primaryCTA?.link?.href
    const parsedData = {
      ...subtitleText,
      title: data.title,
      image: data.image,
      linkText: data.primaryCTA?.text,
      termsAndConditions: createPortableTextElement(data.termsAndConditionsProps),
      url: `${href}${href?.includes('?') ? '&' : '?'}cta=${data.campaignId}`,
      openNewTab: data.primaryCTA?.link?.blank,
      tags: data.tags,
    }
    window.document.dispatchEvent(
      ModalTriggerEvent({
        modalType: ModalTypes.PROMO,
        // @ts-expect-error: check props
        props: parsedData,
        triggerType: ModalTriggerTypes.POPUP,
        popupInfo: data,
      } satisfies PromoPopupEventDetails)
    )
    return
  }
}

export const openNewsletterHeader = () => {
  window.document.dispatchEvent(
    ModalTriggerEvent({
      modalType: ModalTypes.NEWSLETTER,
      props: { method: SUBSCRIBE_METHOD.NAV },
      triggerType: ModalTriggerTypes.CTA,
    })
  )
}
export const openNewsletterFooter = () => {
  window.document.dispatchEvent(
    ModalTriggerEvent({
      modalType: ModalTypes.NEWSLETTER,
      props: { method: SUBSCRIBE_METHOD.FOOTER },
      triggerType: ModalTriggerTypes.CTA,
    } satisfies NewsletterPopupEventDetails)
  )
}
