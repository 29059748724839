import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import type { ExhibitionsDateFilterType } from '@/common/api/campaigns/filterSection'

dayjs.extend(utc)
dayjs.extend(timezone)

export type EventPeriod = Partial<{
  startDate: string
  endDate: string
}>

// TZ of the dates we fetch from Sanity is America/New_York
const tz = 'America/New_York'

/**
 * Get the status of an event based on its start and end date
 * @param pageContext - The event period
 * @returns The status of the event (current, upcoming, previous) or null if the dates are invalid or missing
 */
export const getEventStatus = (pageContext?: EventPeriod): ExhibitionsDateFilterType | null => {
  if (pageContext === undefined) {
    return null
  }
  if (!pageContext.startDate || !pageContext.endDate) {
    return null
  }

  const startDate = dayjs(pageContext.startDate).tz(tz, true)
  if (!startDate.isValid()) {
    return null
  }
  const endDate = dayjs(pageContext.endDate).add(1, 'day').tz(tz, true)
  if (!endDate.isValid()) {
    return null
  }

  const now = dayjs()

  if (now.isAfter(startDate) && now.isBefore(endDate)) {
    return 'current'
  } else if (now.isBefore(startDate)) {
    return 'upcoming'
  } else {
    return 'previous'
  }
}
