import { FORM_MODAL_TYPES } from '@zwirner/design-system'
import type { ComponentProps } from 'react'

import { DzFormModal } from '../wrappers/DzFormModalWrapper'

export const RecaptchaInquireFormModal = (
  props: Omit<ComponentProps<typeof DzFormModal>, 'type'>
) => {
  // TODO: BUILD NEW FORM IN MODAL THERE
  return <DzFormModal {...props} type={FORM_MODAL_TYPES.INQUIRE} />
}
