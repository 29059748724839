import { DzHeader as DzHeaderBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzHeaderBasicProps = ComponentProps<typeof DzHeaderBasic>
type Props = Omit<DzHeaderBasicProps, 'LinkElement'>

export const DzHeader = (props: Props) => {
  const dzComplexGridProps = { ...props, LinkElement: Link } as DzHeaderBasicProps
  return <DzHeaderBasic {...dzComplexGridProps} />
}

DzHeader.displayName = 'DzHeaderWrapper'
