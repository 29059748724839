import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  CLOSE,
  EXPECT_THE_LATEST_INFORMATION,
  JOIN_OUR_MAILING_LIST,
  JOIN_OUR_MAILING_LIST_ERROR,
  JOIN_OUR_MAILING_LIST_SUCCESS,
  SIGN_UP,
  WANT_TO_KNOW_MORE,
} from '@/common/constants/commonCopies'
import type { SubscribeMethod } from '@/common/constants/subscribe'
import { ErrorType, GTMErrorMessageEvent } from '@/common/utils/gtm/GTMErrorMessageEvent'
import { gtmPopupClickedEvent, TypeTypes } from '@/common/utils/gtm/gtmPopupEvent'
import { captchaInitObserver, removeCaptchaObserver } from '@/common/utils/recaptcha/observer'
import useGtmNewsletterEvent from '@/components/hooks/gtm/useGtmNewsletterEvent'
import { MethodTypes, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { isEmailGateEnabled } from '@/featureFlag'
import type { SubscriptionData } from '@/services/subscribeService'
import { sendSubscribeRequest } from '@/services/subscribeService'
import { setUserEmailCollectedExpirationInDays } from '@/store/emailGateStore'

import type { CommonModalProps } from './types'

const RecaptchaNode = dynamic(
  () => import('@/components/forms/recaptchaNode').then((mod) => mod.RecaptchaNode),
  {
    ssr: false,
  }
)

type Props = {
  disableBackdrop?: boolean
  usePortalForRecaptcha?: boolean
}

export type NewsletterModalProps = Partial<
  {
    useAnchor: boolean
    method: SubscribeMethod
  } & CommonModalProps
> | null

export const SUBSCRIBE_HASH_KEY = 'subscribe'

export const useNewsletterFormModal = (props?: Props) => {
  const { disableBackdrop = false, usePortalForRecaptcha = false } = props ?? {}
  const [isOpen, setIsOpen] = useState(false)
  const { replace } = useRouter()
  const [customModalProps, setCustomModalProps] = useState<NewsletterModalProps>(null)
  const [triggerType, setTriggerType] = useState<ModalTriggerTypes | null>(null)
  const recaptchaRef = useRef<HTMLFormElement>()
  const { gtmNewsletterSubscriptionStartedEvent, gtmNewsletterSubscribedEvent } =
    useGtmNewsletterEvent()
  const onDirty = gtmNewsletterSubscriptionStartedEvent
  const onClose = () => {
    const { pathname } = window.location
    replace({ pathname, hash: '' }, undefined, { scroll: false })
    setIsOpen(false)
    setCustomModalProps(null)
    if (triggerType === ModalTriggerTypes.POPUP) {
      gtmPopupClickedEvent({
        cta_value: SIGN_UP,
        method: MethodTypes.CENTER,
        type: TypeTypes.FORM,
        link_url: CLOSE,
      })
    }
  }

  const newsletterFormProps = {
    title: customModalProps?.title || WANT_TO_KNOW_MORE,
    subtitle: customModalProps?.portableTextSubtitle
      ? null
      : customModalProps?.subtitle || JOIN_OUR_MAILING_LIST,
    portableTextSubtitle: customModalProps?.portableTextSubtitle,
    termsAndConditions: customModalProps?.termsAndConditions,
    successTitle: JOIN_OUR_MAILING_LIST_SUCCESS,
    successSubtitle: EXPECT_THE_LATEST_INFORMATION,
    isOpen,
    onClose,
    errorTitle: JOIN_OUR_MAILING_LIST_ERROR,
    onSubmit: async (data: SubscriptionData) => {
      gtmNewsletterSubscribedEvent(data)
      const observer = captchaInitObserver()
      await recaptchaRef?.current?.executeAsync()
      removeCaptchaObserver(observer)
      const response = await sendSubscribeRequest(
        { ...data, tags: customModalProps?.tags },
        window.location.href
      )
      if (!response.isSuccess)
        GTMErrorMessageEvent({
          error_message: response.error.message,
          type: ErrorType.FORM,
        })
      if (response.isSuccess && isEmailGateEnabled) {
        setUserEmailCollectedExpirationInDays(30)
      }
      return response
    },
    tags: customModalProps?.tags,
    onDirty,
    disableBackdrop,
    recaptchaNode: usePortalForRecaptcha ? (
      createPortal(<RecaptchaNode recaptchaRef={recaptchaRef} />, document.body)
    ) : (
      <RecaptchaNode recaptchaRef={recaptchaRef} />
    ),
    image: customModalProps?.image ? customModalProps.image : null,
    primaryCTA: customModalProps?.primaryCTA ? customModalProps.primaryCTA : null,
  }

  const openNewsletterModal = (
    modalProps: NewsletterModalProps,
    triggerType: ModalTriggerTypes,
    options: { useAnchor?: boolean }
  ) => {
    const { pathname, search, hash } = window.location
    if (options?.useAnchor && !hash.includes(`#${SUBSCRIBE_HASH_KEY}`)) {
      replace({
        pathname,
        query: search,
        hash: SUBSCRIBE_HASH_KEY,
      })
    }
    setIsOpen(true)
    if (modalProps) {
      setCustomModalProps(modalProps)
    }
    setTriggerType(triggerType)
  }

  return {
    newsletterFormProps,
    openNewsletterModal,
  }
}
