import type { InquireFormContextData, InquiryCategory } from '@zwirner/design-system'
import { INQUIRY_CATEGORIES } from '@zwirner/design-system'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import {
  INQUIRE,
  INTERESTED_IN,
  PLEASE_PROVIDE_YOUR_CONTACT_SHORT,
  THIS_ARTWORK,
  TO_LEARN_MORE_ABOUT,
  TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
  TO_LEARN_MORE_ABOUT_THESE_WORKS,
  TO_LEARN_MORE_ABOUT_THIS_ARTWORK,
} from '@/common/constants/commonCopies'
import { INQUIRE_HASH_KEY } from '@/components/hooks/useHashRoutedInquiryModal'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import type { ArtworkContentType } from '@/sanity/queries/components/content/artworkContent'
import { ModalTypes } from '@/sanity/types'

export type InquireModalProps = {
  contextData?: InquireFormContextData
  inquiryCategory: InquiryCategory
  subtitle: string
  title: string
  useAnchor?: boolean
}

export const useOpenInquiryDispatch = (inquireModalProps: InquireModalProps) => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (asPath.includes(`#${INQUIRE_HASH_KEY}`)) {
      window.document.dispatchEvent(
        ModalTriggerEvent({
          modalType: ModalTypes.INQUIRE,
          props: inquireModalProps,
          triggerType: ModalTriggerTypes.CTA,
        })
      )
    }
  }, [asPath, inquireModalProps])
}

export const createInquireModalExhibitionProps = (
  exhibition: Record<string, any>
): InquireModalProps => {
  const { title, subtitle } = exhibition
  const exhibitionTitle = `${title}${subtitle ? `: ${subtitle}` : ''}`
  const ctaText = INQUIRE // there is no ctaText for exhibitions

  return {
    contextData: {
      status: INQUIRE,
      ctaText,
      id: exhibition._id,
      title: exhibition.title,
    },
    useAnchor: true,
    inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
    subtitle: TO_LEARN_MORE_ABOUT_THESE_WORKS,
    title: `${INTERESTED_IN} ${exhibitionTitle}?`,
  }
}

export const createInquireModalArtistProps = (artist: Record<string, any>): InquireModalProps => {
  const ctaText = INQUIRE // there is no ctaText for artists

  return {
    contextData: {
      status: INQUIRE,
      ctaText,
      id: artist.id,
      title: artist.fullName,
    },
    inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
    useAnchor: true,
    subtitle: `${TO_LEARN_MORE_ABOUT} ${artist.fullName}, ${PLEASE_PROVIDE_YOUR_CONTACT_SHORT}`,
    title: INQUIRE,
  }
}

export const createInquireModalArtworkProps = (
  artwork: ArtworkContentType | undefined,
  props?: { title?: string; subtitle?: string }
): InquireModalProps => {
  const [mainArtist] = artwork?.artists ?? []
  const { fullName } = mainArtist ?? {}
  const ctaText = artwork?.artworkCTA?.primaryCTA?.text || INQUIRE

  return {
    contextData: {
      status: INQUIRE,
      ctaText,
      id: artwork?._id as string,
      title: fullName || 'this artist',
      artwork: artwork as InquireFormContextData['artwork'],
    },
    useAnchor: true,
    inquiryCategory: INQUIRY_CATEGORIES.ARTWORK,
    subtitle: props?.subtitle || TO_LEARN_MORE_ABOUT_THIS_ARTWORK,
    title: props?.title || `${INTERESTED_IN} ${mainArtist?.fullName || THIS_ARTWORK}?`,
  }
}

export const createInquireModalGeneralProps = (): InquireModalProps => {
  return {
    inquiryCategory: INQUIRY_CATEGORIES.GENERAL,
    subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
    title: INQUIRE,
  }
}
