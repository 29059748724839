import { env } from './env'

const isBrazeEnabled: { sdk: boolean; api: boolean } = {
  true: { sdk: true, api: true },
  false: { sdk: false, api: false },
  sdk: { sdk: true, api: false },
  api: { sdk: false, api: true },
}[env.NEXT_PUBLIC_FEATURE_FLAG_BRAZE_ENABLED]
const isEmailGateEnabled = env.NEXT_PUBLIC_FEATURE_FLAG_EMAIL_GATE_ENABLED
const isLighthouseAnalyticsEnabled = env.NEXT_PUBLIC_FEATURE_FLAG_LIGHTHOUSE_ANALYTICS_ENABLED

export { isBrazeEnabled, isEmailGateEnabled, isLighthouseAnalyticsEnabled }
