import { useEffect } from 'react'

type dispatched = {
  [key: number]: number
}

export const useTrustArcConsentEventListener = () => {
  useEffect(() => {
    let dispatched: dispatched = {}
    let intervalId: any
    const postMessageInterval = () => {
      if (window.PrivacyManagerAPI && intervalId) {
        const apiObject = {
          PrivacyManagerAPI: {
            action: 'getConsentDecision',
            timestamp: new Date().getTime(),
            self: window.location.host,
          },
        }
        if (window.top) {
          window.top.postMessage(JSON.stringify(apiObject), '*')
        }
        intervalId = clearInterval(intervalId)
      }
    }

    const messageListener = (e: any) => {
      try {
        let data
        if (
          e.data &&
          (data = JSON.parse(e.data)) &&
          (data = data.PrivacyManagerAPI) &&
          data.capabilities &&
          data.action === 'getConsentDecision'
        ) {
          const newDecision = window.PrivacyManagerAPI.callApi(
            'getGDPRConsentDecision',
            window.location.host
          ).consentDecision

          newDecision &&
            newDecision.forEach((label: number) => {
              if (!dispatched[label]) {
                window.dataLayer && window.dataLayer.push({ event: 'GDPR Pref Allows ' + label })
                dispatched[label] = 1
              }
            })
        }
      } catch (_error) {
        // not a cm api message
      }
    }

    intervalId = setInterval(postMessageInterval, 50)

    window.addEventListener('message', messageListener)

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      window.removeEventListener('message', messageListener)
    }
  }, [])
}
