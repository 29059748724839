import { DzFooter as DzFooterBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzFooterProps = ComponentProps<typeof DzFooterBasic>
type Props = Omit<DzFooterProps, 'LinkElement'>

export const DzFooter = (props: Props) => {
  const dzFooterProps = { ...props, LinkElement: Link } as DzFooterProps
  return <DzFooterBasic {...dzFooterProps} />
}

DzFooter.displayName = 'DzFooterWrapper'
