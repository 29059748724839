globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"42490f4710c5a311624451abf5aee14f8151c883"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  browserTracingIntegration,
  defaultStackParser,
  functionToStringIntegration,
  getCurrentScope,
  globalHandlersIntegration,
  httpContextIntegration,
  inboundFiltersIntegration,
  makeFetchTransport,
} from '@sentry/nextjs'

import { env } from './src/env'

/**
 * Indicates whether the current environment anticipates a lot of requests
 * relative to other environments.
 */
const isHighTrafficEnvironment = env.NEXT_PUBLIC_SENTRY_ENVIRONMENT == 'production'

const client = new BrowserClient({
  // all options you normally pass to Sentry.init
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  environment: env.NEXT_PUBLIC_SENTRY_ENVIRONMENT + '-client',
  sampleRate: isHighTrafficEnvironment ? 1.0 : 1.0, // was 0.1
  tracesSampleRate: isHighTrafficEnvironment ? 1.0 : 1.0, // was 0.05
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: isHighTrafficEnvironment ? 0.75 : 1.0, // was 0.2
  enabled: env.NEXT_PUBLIC_SENTRY_ENABLED,
  // ...
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  // Only the integrations listed here will be used
  integrations: [
    // common default integrations. Less then 1kb together.
    // dedupeIntegration and linkedErrorsIntegration are lazy loaded
    functionToStringIntegration(),
    inboundFiltersIntegration(),
    // browser default integrations. 13kb together
    breadcrumbsIntegration(),
    browserApiErrorsIntegration(),
    browserTracingIntegration(), // 13kb
    globalHandlersIntegration(),
    httpContextIntegration(),
  ],
  // Called for message and error events
  beforeSend(event) {
    // Modify or drop the event here
    if (event) {
      if (event.tags?.isStaticPage === 'true') return null
    }
    return event
  },
})

getCurrentScope().setClient(client)
client.init()

import('./src/lazy-load/sentry').then((lazyImports) => {
  lazyImports.addLazyIntegration()
})
