import { FORM_MODAL_TYPES } from '@zwirner/design-system'
import { useRouter } from 'next/router'
import type { ComponentProps } from 'react'

import { DzFormModal } from '../wrappers/DzFormModalWrapper'

export const NewsletterFormModal = (props: Omit<ComponentProps<typeof DzFormModal>, 'type'>) => {
  // TODO: BUILD NEW FORM IN MODAL THERE
  const router = useRouter()
  return (
    <DzFormModal
      {...props}
      type={FORM_MODAL_TYPES.NEWSLETTER}
      isDz30={router.asPath.includes('30years')}
    />
  )
}
