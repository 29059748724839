import { groq } from 'next-sanity'
import { z } from 'zod'

const PageSectionsTypeSchema = z.enum([
  'article',
  'artistPage',
  'exhibitionPage',
  'exceptionalWork',
  'fairPage',
  'onlineExhibitionPage',
  'hongkong',
  'availableArtworks',
  'artistListing',
  'exhibitionsLanding',
  'exhibitionsPast',
  'home',
  'page',
  'singlePageRecord',
  'customSlug',
])

const ExhibitionsDateFilterSchema = z.enum(['previous', 'current', 'upcoming'])
export type ExhibitionsDateFilterType = z.infer<typeof ExhibitionsDateFilterSchema>

export const filterSectionsFields = groq`
  type,
  openStatusFilter,
  type == "singlePageRecord" || type == "page" => {
    page -> {
      _type,
      'url': slug.current,
    },
  },
  type == "customSlug" => {
    'page': {
      '_type': 'customSlug',
      'url': customSlug,
    }
  },
  'excludedSlugs': [
    ...(excludedPages[] -> slug.current),
    ...excludedCustomSlugs,
  ],
`

export const FilterSectionsSchema = z.array(
  z.object({
    type: PageSectionsTypeSchema,
    openStatusFilter: z.nullable(ExhibitionsDateFilterSchema),
    page: z
      .object({
        _type: z.string(),
        url: z.string().nullish(),
      })
      .nullish(),
    excludedSlugs: z.array(z.string()),
  })
)
