import { DzPortableText as DzPortableTextBasic } from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzPortableTextProps = ComponentProps<typeof DzPortableTextBasic>
type Props = Omit<DzPortableTextProps, 'LinkElement' | 'ImgElement'>

export const DzPortableText = (props: Props) => {
  const dzPortableTextProps = {
    ...props,
    LinkElement: Link,
    ImgElement: Image,
  } as DzPortableTextProps
  return <DzPortableTextBasic {...dzPortableTextProps} />
}

DzPortableText.displayName = 'DzPortableTextWrapper'
