import type { ReactNode } from 'react'
import { createContext, createElement, useContext } from 'react'

type PageMetadataContextType = Record<string, unknown>

const initialContext: PageMetadataContextType = {}

export const PageMetadataContext = createContext(initialContext)

export const PageMetadataProvider = ({
  context,
  children,
}: {
  children: ReactNode
  context: PageMetadataContextType
}) => createElement(PageMetadataContext.Provider, { value: context }, children)

export const usePageMetadata = <T = unknown>() => {
  const context = useContext(PageMetadataContext)
  return context as T
}
