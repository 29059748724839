import type { ButtonVariant, CardSizes, EditorialType } from '@zwirner/design-system'

import type { DzSplitTypeProps } from '@/sanity/queries/components/dzSplitProps'
import type { PageState } from '@/store/pageStore'

import type { LinkType } from './queries/components/builders/ctaBuilder'
import type { MediaBuilderSchemaType } from './queries/components/builders/mediaBuilder'
import type { ArtworkFiltersType, BookFiltersType } from './queries/components/displayFilters'
import type {
  DzCardPropsDataType,
  DzCustomCardPropsDataType,
} from './queries/components/dzCardProps'
import type { ModeSchemaType } from './queries/components/dzInterstitialProps'
import type { BasicContentType } from './queries/components/portableText/basicContent'

// UTILS

export type BreadcrumbItemSchema = {
  name: string
  item: string
}

export enum EditorialTextTypes {
  PARAGRAPH = 'paragraph',
  QUOTE = 'quote',
}

export enum ModalTypes {
  INQUIRE = 'inquire',
  NEWSLETTER = 'newsletter',
  REGISTERED_INTEREST = 'registeredInterest',
  PROMO = 'promo',
  REQUEST_A_PREVIEW = 'RequestPreview',
}

export enum CTAActionTypes {
  ECOMM = 'ecomm',
  NONE = 'none',
  SOLD_OUT = 'Sold Out',
  LINK = 'Link',
  DOWNLOAD_PDF = 'Download PDF',
  LINK_CONTENT = 'Link Content',
  INQUIRE = 'inquire',
  NEWSLETTER = 'newsletter',
  REGISTERED_INTEREST = 'Registered Interest',
  REQUEST_A_PREVIEW = 'Request a Preview',
}

export enum ArticleCategories {
  PRESS = 'Press',
  NEWS = 'News',
  EVENT = 'Event',
  MUSEUM_EXHIBITION = 'Museum Exhibition',
  MUSEUM_HIGHLIGHTS = 'Museum Highlights',
}

export enum ArticleTypes {
  INTERNAL = 'internalNews',
  PRESS = 'pressRelease',
  EXTERNAL = 'externalNews',
}

export enum BookVariation {
  PRODUCT = 'productCard',
  CONTENT = 'contentCard',
}

export enum MediaTypes {
  IMAGE = 'Image',
  VIDEO = 'Custom Video',
  VIDEO_RECORD = 'Video Record',
}

export type CTASchemaType = {
  type?: 'button' | 'link'
  action?: CTAActionTypes
  text?: string
  link?: LinkType
  variant?: ButtonVariant
}

type SimpleEditorialText = {
  _key: string
  _type: string
  text: string
}

type TextComplexSchemaType = {
  text?: SimpleEditorialText[] | null
  textType: EditorialTextTypes
  pullQuoteTitle?: any[] | null
  pullQuoteAttribution?: any[] | null
}

// MOLECULES
type DzCardWebProps = {
  isInGrid: boolean
  cardSize: any
  pageData: PageState
  artworkFilters: ArtworkFiltersType | null | undefined
  bookFilters: BookFiltersType | null | undefined
  handleLineAdd: any
}

export type DzCardExtendedProps =
  | (DzCardPropsDataType & DzCardWebProps)
  | (DzCustomCardPropsDataType & DzCardWebProps)

export type DzMediaSchemaProps = {
  media: MediaBuilderSchemaType | null
  title: string
  cardSize?: CardSizes
}

export type DzEditorialSchemaProps = {
  title: string
  editorialType: EditorialType
  editorialTextOverrides?: TextComplexSchemaType[] | null
  editorialTextOverridesSimple?: TextComplexSchemaType['text'] | null
  editorialTextOverridesLeftBlock?: TextComplexSchemaType[] | null
  editorialTextOverridesCentered?: TextComplexSchemaType[] | null
  imageOverride?: DzMediaSchemaProps[]
  quoteTitle?: string
  quoteFootNote?: string
  reverse?: boolean
}

export type DzInterstitialTypeProps = {
  title: string
  mode: ModeSchemaType
  cta: any
  image: any
  subtitle: string
  eyebrow: string
  tags?: string[]
  registeredInterest: {
    title: string | null
    description: BasicContentType[] | null
  } | null
}

export type DzSplitTypeExtendedProps = DzSplitTypeProps & {
  router?: any
}
