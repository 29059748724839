import '@/styles/globals.css'
import '@zwirner/design-system/dist/tailwind.css'

import { GoogleTagManager } from '@next/third-parties/google'
import * as Sentry from '@sentry/nextjs'
import { DzSpinner } from '@zwirner/design-system'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { APIProvider } from '@/common/api'
import { Braze } from '@/common/components/braze'
import Layout, { type DefaultLayoutProps } from '@/common/components/layout'
import { AvenirNext } from '@/common/components/layout/fonts'
import { PageMetadataProvider } from '@/common/components/popups/PageMetaDataContext'
import { DefaultSEOComponent } from '@/common/components/seo/seo'
import { TrustArcBanner } from '@/common/components/trustArc/TrustArcBanner'
import { DRAFTMODE_SENTRY } from '@/common/constants/commonCopies'
import { GTMPageLoadStartedText } from '@/common/constants/gtmConstants'
import { useShopifyCart } from '@/common/loaders/useShopifyCart'
import { gtmEvent } from '@/common/utils/gtm/gtmEvent'
import { useTrustArcCustomizations } from '@/common/utils/trustArc/trustArcCustomizations'
import { useTrustArcConsentEventListener } from '@/common/utils/trustArc/trustArcEventListener'
import { useTrustArcReloadPage } from '@/common/utils/trustArc/trustArcReloadListener'
import useScrollDepthEvent from '@/components/hooks/gtm/useScrollDepthEvent'
import { useLandingPage } from '@/components/hooks/useLandingPageStore'
import { env } from '@/env'
import type { LayoutProps } from '@/sanity/services/common/getCommonData'
import usePageStore from '@/store/pageStore'
import type { LayoutResolver } from '@/types/appLayout'

import { ErrorBoundary } from '../components/wrappers/ErrorBoundary'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: LayoutResolver
}

type PageProps = Record<string, unknown> & {
  layoutProps: DefaultLayoutProps & LayoutProps
  data: {
    _type?: string
  } & Record<string, unknown>
} & JSX.IntrinsicAttributes

type WrapperProps = AppProps<PageProps> & {
  Component: NextPageWithLayout
}

const Wrapper = ({ Component, pageProps }: WrapperProps) => {
  const draftMode = pageProps.draftMode as boolean

  Sentry.setTag('draftMode', draftMode ? 'true' : draftMode === false ? 'false' : 'undefined')
  Sentry.setTag('isStaticPage', pageProps.data?.isStaticPage ? 'true' : 'false')
  Sentry.addEventProcessor((event) => {
    if (draftMode) {
      if (event.transaction && !event.transaction.startsWith(DRAFTMODE_SENTRY)) {
        event.transaction = DRAFTMODE_SENTRY + event.transaction
      }
    }
    return event
  })

  const TrustArcConsent = dynamic(() => import('../common/components/trustArc/TrustArcConsent'), {
    ssr: false,
  })

  const getLayout: LayoutResolver<typeof pageProps> = (page, pageProps) =>
    Component.getLayout?.(page, pageProps) || (
      <Layout pageType={pageProps.data?._type} layoutData={pageProps.layoutProps.layoutData}>
        {page}
      </Layout>
    )

  if (draftMode) {
    return (
      <APIProvider>
        <PageMetadataProvider context={pageProps.data}>
          <Component {...pageProps} />
        </PageMetadataProvider>
      </APIProvider>
    )
  }

  return (
    <APIProvider>
      <Braze />
      <DefaultSEOComponent globalSEO={pageProps.layoutProps.globalSEO} />
      <PageMetadataProvider context={pageProps.data}>
        {getLayout(<Component {...pageProps} />, pageProps)}
      </PageMetadataProvider>
      <div id="scroll-observer-target" />
      <TrustArcBanner />
      <TrustArcConsent />
    </APIProvider>
  )
}

function DzApp(props: AppProps) {
  const router = useRouter()

  const setPageState = usePageStore((state) => state.setPageState)

  useScrollDepthEvent({})

  useEffect(() => {
    const gtmData = props.pageProps.dataLayerProps
    const pageLoadStarted = () => {
      if (gtmData) {
        setPageState({
          title: gtmData.page_data.page_title,
          hash: gtmData.page_data.page_hash,
          section: gtmData.page_data.site_section,
        })
        gtmData.page_data.page_location = document.location.href
        gtmData.page_data.page_hostname = document.location.hostname
        gtmData.page_data.page_path = document.location.pathname
        gtmData.page_data.page_query_string = document.location.search
        gtmData.page_data.page_query_hash = document.location.hash
        gtmData.page_data.language = document.documentElement.lang
        gtmData.page_data.page_title = document.title
        return gtmEvent(GTMPageLoadStartedText.event, gtmData)
      }
    }
    pageLoadStarted()
  }, [setPageState, props.pageProps.data?.artistPages, props.pageProps.dataLayerProps])

  useTrustArcConsentEventListener()
  useTrustArcReloadPage()
  useTrustArcCustomizations()

  useLandingPage()
  useShopifyCart()
  useEffect(() => {
    // For compatibility with <a> links. After we don't use anchor tags, we can remove this.
    const setScrollRestoration = () => {
      window.history.scrollRestoration = 'auto'
    }

    const handleRouteChange = () => {
      setPageState({ title: '', hash: '', section: '' })
    }

    setScrollRestoration()
    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', setScrollRestoration)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', setScrollRestoration)
    }
  }, [router.events, setPageState])

  if (router.isFallback) {
    return <DzSpinner />
  }

  return (
    <ErrorBoundary>
      <GoogleTagManager gtmId={env.NEXT_PUBLIC_GTM_ID} />
      <div className={`${AvenirNext.variable} font-sans font-medium`}>
        <Wrapper {...props} />
      </div>
    </ErrorBoundary>
  )
}

export default DzApp
