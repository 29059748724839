import { DzFormModal as DzFormModalBasic } from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzFormModalProps = ComponentProps<typeof DzFormModalBasic>
type Props = Omit<DzFormModalProps, 'LinkElement' | 'ImgElement'>

export const DzFormModal = (props: Props) => {
  const dzFormModalProps = { ...props, LinkElement: Link, ImgElement: Image } as DzFormModalProps
  return <DzFormModalBasic {...dzFormModalProps} />
}

DzFormModal.displayName = 'DzFormModalWrapper'
