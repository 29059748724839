import type { ShopifyClient, ShopifyComponent } from '@/components/hooks/shopify/common'
import { ComponentVariant, getVariantProps } from '@/components/hooks/shopify/common'

export const createShopifyCart = (client: ShopifyClient) => {
  const ui = ShopifyBuy.UI.init(client)
  const mixedOptions = getVariantProps({
    styleVariant: ComponentVariant.PRIMARY_BUTTON,
    options: {},
  })
  ui.createComponent('cart', {
    // Useful for migrating the shopify option to the nav
    //   node: document.getElementById('default-shopify'),
    //   toggles: [{node: document.getElementById('shopify-cart')}],
    options: {
      cart: mixedOptions.cart,
      lineItem: mixedOptions.lineItem,
      toggle: mixedOptions.toggle,
    },
  })
}

export const createShopifyComponent = (client: ShopifyClient, component: ShopifyComponent) => {
  const ui = ShopifyBuy.UI.init(client)
  const { productId, nodeId, componentType, variant, text } = component ?? {}
  const componentOptions = text
    ? {
        text: {
          button: text,
        },
      }
    : undefined
  const options = getVariantProps({
    styleVariant: variant,
    options: componentOptions,
  })
  const primaryButton = document.getElementById(nodeId)
  if (!primaryButton || primaryButton?.hasChildNodes()) return
  ui.createComponent(componentType, {
    id: productId,
    node: primaryButton,
    options,
  })
}
