import * as Sentry from '@sentry/nextjs'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { loadScriptToDOM, SCRIPT_ID } from '@/common/loaders/shopifyLoader'
import { createShopifyCart, createShopifyComponent } from '@/common/loaders/shopifyUICreator'
import type { ShopifyClient, ShopifyComponent } from '@/components/hooks/shopify/common'
import { env } from '@/env'

const SHOPIFY_SCRIPT = 'https://sdks.shopifycdn.com/buy-button/2.4.0/buybutton.min.js'

type LoadedItems = Record<string, boolean>

export type ShopifyState = {
  client: any
  components: ShopifyComponent[]
  loadedItems: LoadedItems
}

type ShopifyStore = ShopifyState & {
  setClient: (client: ShopifyClient) => void
  initClient: () => void
  createCart: () => void
  createComponents: () => void
  setComponentsList: (components: ShopifyComponent[]) => void
  setLoadedItems: (items: LoadedItems) => void
}

const INITIAL_STATE: ShopifyState = {
  client: null,
  components: [],
  loadedItems: {},
}

const useShopifyStore = create<ShopifyStore>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    setClient: (client) => {
      set(() => ({ client: client }), false, 'setClient')
      get().createCart()
      get().createComponents()
    },
    createCart: () =>
      set(({ client }) => {
        if (!client) return { client }
        createShopifyCart(client)
        return { client }
      }),
    setComponentsList: (components) => {
      set(() => ({ components: components }), false, 'setComponentsList')
      get().createComponents()
    },
    createComponents: () =>
      set(
        ({ client, components }) => {
          if (!client || !components) return { client }
          components.forEach((component) => {
            createShopifyComponent(client, component)
          })
          return { components: [] }
        },
        false,
        'createComponents'
      ),
    initClient: () => {
      const {
        NEXT_PUBLIC_STORE_DOMAIN: shopifyStore,
        NEXT_PUBLIC_STOREFRONT_API_TOKEN: storefrontAccessToken,
      } = env
      if (!shopifyStore || !storefrontAccessToken) {
        console.error('Shopify environment variables are not set')
        return
      }

      const shopifyUrl = new URL(shopifyStore)
      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.async = true
      script.src = SHOPIFY_SCRIPT

      loadScriptToDOM(script)
        .then(() => {
          const shopifyClient = ShopifyBuy.buildClient({
            domain: shopifyUrl.host,
            storefrontAccessToken,
          })
          get().setClient(shopifyClient)
        })
        .catch((error) => {
          Sentry.captureException(`Failed to load Shopify script after multiple attempts:`, {
            level: 'error',
            extra: { error },
          })
        })
    },
    setLoadedItems: (items) => set(() => ({ loadedItems: items }), false, 'setLoadedItems'),
  }))
)

export default useShopifyStore
