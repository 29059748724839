import * as Sentry from '@sentry/nextjs'
import { type ClientConfig, createClient, type SanityClient } from 'next-sanity'

import { DRAFT_MODE_SANITY_READ_TOKEN_ERROR } from '@/common/constants/errorMessages'
import { env } from '@/env'
import { getStringSize } from '@/utils/string/getSize'

import { minGroQ } from './util/minifyGroq'

// https://www.sanity.io/docs/technical-limits#4467b1100330
const MAX_POST_SIZE_CDN_API = 300

export const config: ClientConfig = {
  apiVersion: env.NEXT_PUBLIC_SANITY_API_VERSION,
  dataset: env.NEXT_PUBLIC_SANITY_DATASET,
  projectId: env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: false,
  perspective: 'published',
  stega: {
    enabled: false,
  },
  // this amount of retries and delay is huge, we should consider reducing it
  maxRetries: 16,
  retryDelay: (attempt) => Math.min(100 * 2 ** attempt, 32000) + Math.random() * 1000,
}

export const cdnClientConfig: ClientConfig = {
  ...config,
  useCdn: true,
}

type GetClientTypeProps = (props: {
  slug?: string
  originName: string
  filePath?: string
  draftMode: boolean
  draftViewToken: string
  revalidateReason?: string
  isErrorPage?: boolean
}) => { client: SanityClient }

type ISanityClient = typeof SanityClient.prototype

type SanityProxyProps = {
  originalClient: ISanityClient
  slug?: string
  originName: string
  filePath?: string
  cdnEnabled?: boolean
}

function createSanityClientProxy({
  originalClient,
  slug,
  originName,
  filePath,
  cdnEnabled = false,
}: SanityProxyProps): ISanityClient {
  return new Proxy(originalClient, {
    get(target, prop, receiver) {
      if (prop === 'fetch') {
        return function (...args: Parameters<ISanityClient['fetch']>) {
          const [query = '', ...rest] = args
          const minifiedQuery = minGroQ(query)
          const minQuerySizeKb = getStringSize(minifiedQuery)
          if (minQuerySizeKb > MAX_POST_SIZE_CDN_API && cdnEnabled) {
            Sentry.captureException(`Error on query size above threshold`, {
              level: 'error',
              extra: { url: slug, query, querySize: minQuerySizeKb },
            })
          }
          return Sentry.startSpan(
            {
              name: originName || 'SanityClientFetchDefault',
              // Check https://github.com/open-telemetry/semantic-conventions/blob/main/docs/general/trace.md for details
              attributes: {
                url: slug,
                'url.query': minifiedQuery,
                'http.request.body.size': minQuerySizeKb,
                'code.function': originName,
                'code.filepath': filePath ?? '/',
              },
            },
            () => {
              return (target as any).fetch.apply(target, [minifiedQuery, ...rest])
            }
          )
        }
      }

      // For all other properties and methods, including 'config'
      const value = Reflect.get(target, prop, receiver)
      return typeof value === 'function' ? value.bind(target) : value
    },
  }) as ISanityClient
}

export const getClient: GetClientTypeProps = (props) => {
  const {
    draftMode = false,
    draftViewToken,
    revalidateReason,
    isErrorPage = false,
    slug,
    originName,
    filePath,
  } = props
  if (draftMode && !draftViewToken && !isErrorPage)
    throw new Error(DRAFT_MODE_SANITY_READ_TOKEN_ERROR)

  const CDN_ENABLED = revalidateReason !== 'on-demand' && env.NEXT_PUBLIC_ENABLE_QUERY_OPTIMIZATION
  const client = CDN_ENABLED ? createClient(cdnClientConfig) : createClient(config)

  if (draftMode && draftViewToken) {
    const draftClient = client.withConfig({
      token: draftViewToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts',
      stega: {
        enabled: false,
        logger: console,
      },
    })
    const proxyClient = createSanityClientProxy({
      originalClient: draftClient as ISanityClient,
      slug,
      originName,
      filePath,
    })
    return {
      client: proxyClient,
    }
  }

  const proxyClient = createSanityClientProxy({
    originalClient: client as ISanityClient,
    slug,
    originName,
    filePath,
    cdnEnabled: CDN_ENABLED,
  })
  return { client: proxyClient }
}
