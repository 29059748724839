import {
  DzButtonNew,
  DzImage,
  DzModalContainer,
  DzTitle,
  TITLE_TYPES,
} from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type ReactNode, useEffect } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

type Props = {
  isOpen: boolean
  onClose: (trackGTM?: boolean) => void
  disableBackdrop?: boolean
  title: string
  subtitle?: string
  portableTextSubtitle?: ReactNode
  linkText: string
  image: {
    src: string
    alt: string
  }
  LinkElement: 'a' | React.ElementType
  ImgElement: any
  url: string
  openNewTab: boolean
  onClickCTA: (url: string) => void
}

export const DzPromoModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    onClickCTA,
    title,
    subtitle,
    linkText = 'Explore',
    disableBackdrop = false,
    image,
    url,
    openNewTab = false,
    portableTextSubtitle,
  } = props
  const router = useRouter()

  useEffect(() => {
    const handleClose = () => {
      onClose(false)
    }
    // To close Modal right after user navigates to another page
    router.events.on('routeChangeComplete', handleClose)
    return () => {
      router.events.off('routeChangeComplete', handleClose)
    }
  }, [router.events, onClose])

  return (
    <RemoveScroll enabled={isOpen}>
      <DzModalContainer
        isOpen={isOpen}
        onClose={onClose}
        disableBackdrop={disableBackdrop}
        className="flex w-full max-w-[57.875rem] flex-col !p-0 md:h-[37.5rem] md:flex-row"
      >
        <div className="flex h-full max-w-[57.875rem] flex-col md:flex-row">
          {/* // TODO: remove important after merge of tw configs */}
          <div className="relative block h-60 w-full bg-white-100 md:h-auto md:!w-1/2">
            <DzImage
              Icon={null}
              ImgElement={Image}
              imgClass="h-full"
              imgProps={{
                src: image.src,
                alt: image.alt,
                fill: true,
                sizes: '(max-width: 769px) 728px, 600px',
                priority: true,
                unoptimized: true,
              }}
              className="mb-5"
            />
          </div>
          {/* // TODO: remove important after merge of tw configs */}
          <div className="flex flex-col p-5 md:!w-1/2 md:justify-between">
            <div>
              <DzTitle
                titleType={TITLE_TYPES.H2}
                title={title}
                className="mt-0 md:mt-[0.625rem]"
                classNameTitle="text-xl md:text-xxl"
              />
              {subtitle ? (
                <DzTitle
                  titleType={TITLE_TYPES.P}
                  title={subtitle}
                  className="mt-0 pt-1 md:mt-[0.625rem]"
                  classNameTitle="text-md md:text-lg"
                />
              ) : null}
              {portableTextSubtitle ? (
                <div className="mt-0 pt-1 md:mt-[0.625rem]">{portableTextSubtitle}</div>
              ) : null}
            </div>
            <DzButtonNew asChild className="mt-10" size="lg">
              <Link
                href={url}
                onClick={() => onClickCTA(url)}
                target={openNewTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
              >
                {linkText}
              </Link>
            </DzButtonNew>
          </div>
        </div>
      </DzModalContainer>
    </RemoveScroll>
  )
}
DzPromoModal.displayName = 'DzPromoModal'
