import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { clearShopify, loadShopify } from '@/common/loaders/shopifyLoader'
import useShopifyStore from '@/store/shopifyStore'

export const useShopifyCart = () => {
  const { initClient, createCart } = useShopifyStore()
  const router = useRouter()
  // Load client
  useEffect(() => {
    loadShopify(initClient)
    return () => clearShopify()
  }, [initClient])

  // Load cart
  useEffect(() => {
    createCart()
  }, [router.events, createCart])
}
