import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  /*
   * Server-side Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    SANITY_API_READ_TOKEN: z.string().min(1),
    REVIEW_LINK_READ_TOKEN: z.string().min(1),
    ANALYZE: z
      .string()
      // only allow "true" or "false"
      .refine((s) => s === 'true' || s === 'false')
      // transform to boolean
      .transform((s) => s === 'true'),
    ISR_TOKEN: z.optional(z.string()),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    SENTRY_AUTH_TOKEN: z.string(),
    // Feature flag to enable dz30
    ENABLE_DZ_30_YEARS: booleanString().default('true'),
    NEXT_PRIVATE_STOREFRONT_API_TOKEN: z.string().min(1),
    REVIEW_LINK_PRIVATE_KEY: z.string().min(1),
    ALLOW_ROBOTS: z
      .string()
      // only allow "true" or "false"
      .refine((s) => s === 'true' || s === 'false')
      // transform to boolean
      .transform((s) => s === 'true'),
    SKIP_BUILD_STATIC_GENERATION: booleanString().default('false'),
    GLOBAL_REVALIDATE_VALUE: z
      .string()
      .optional()
      .transform((val) => (val ? Number(val) : false)),
    CAMPAIGNS_REVALIDATION_INTERVAL: z
      .string()
      .optional()
      .transform((val) => (val ? Number(val) : 60)), // default to 60 seconds

    BRAZE_API_KEY: z.string().min(1),
    BRAZE_SUBSCRIBE_TOPIC_NEWS_ID: z.string().min(1),
    BRAZE_SUBSCRIBE_TOPIC_SALES_ID: z.string().min(1),
    BRAZE_SUBSCRIBE_TOPIC_BOOKS_ID: z.string().min(1),
    MAILCHIMP_MEMBERS_ENDPOINT: z.string().url(),
    MAILCHIMP_ACCESS_TOKEN: z.string().min(1),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_RECAPTCHA_SITE_KEY: z.string().min(1),
    NEXT_PUBLIC_GTM_ID: z.string().min(1),

    NEXT_PUBLIC_SANITY_PROJECT_ID: z.string().min(1),
    // Allowed Sanity datasets
    NEXT_PUBLIC_SANITY_DATASET: z.enum([
      '~production',
      '~staging',
      '~dev',
      'staging',
      'dev',
      'release-adp',
    ]),
    NEXT_PUBLIC_SANITY_API_VERSION: z.string().min(1),

    NEXT_PUBLIC_VERCEL_URL: z.string().min(1),
    NEXT_PUBLIC_VERCEL_ENV: z.enum(['production', 'preview', 'development', 'local']),

    NEXT_PUBLIC_SENTRY_DSN: z.string().url(),
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.enum([
      'development',
      'staging-preview',
      'staging',
      'migration',
      'sandbox',
      'production',
      'pre-prod',
      'release-adp',
    ]),

    NEXT_PUBLIC_INQUIRY_ENDPOINT: z.string().url(),
    NEXT_PUBLIC_SUBSCRIBE_ENDPOINT: z.string().url(),
    NEXT_PUBLIC_SUBMIT_CONSIGNMENT_ENDPOINT: z.string().url(),
    NEXT_PUBLIC_GET_PRESIGNED_URL_ENDPOINT: z.string().url(),
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_NEWS_ID: z.string(),
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_SALES_ID: z.string(),
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_BOOKS_ID: z.string(),

    NEXT_PUBLIC_STOREFRONT_API_TOKEN: z.string().min(1),
    NEXT_PUBLIC_STORE_DOMAIN: z.string().min(1),
    NEXT_PUBLIC_STOREFRONT_API_VERSION: z.string().min(1),
    NEXT_PUBLIC_TRUSTARC_ID: z.string().default('xxxxxx'),
    NEXT_PUBLIC_GOSC_RELEASE: booleanString().default('false'),
    NEXT_PUBLIC_SENTRY_ENABLED: booleanString().default('false'),
    NEXT_PUBLIC_STATIC_HOST: z.string().url(),
    NEXT_PUBLIC_ENABLE_QUERY_OPTIMIZATION: booleanString(),
    NEXT_PUBLIC_BRAZE_SDK_KEY: z.string().min(1),
    NEXT_PUBLIC_BRAZE_ENDPOINT: z.string().url(),
    NEXT_PUBLIC_FEATURE_FLAG_BRAZE_ENABLED: z
      .enum(['true', 'false', 'api', 'sdk'])
      .default('false'),
    NEXT_PUBLIC_FEATURE_FLAG_EMAIL_GATE_ENABLED: booleanString().default('false'),
    NEXT_PUBLIC_FEATURE_FLAG_LIGHTHOUSE_ANALYTICS_ENABLED: booleanString().default('false'),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    SANITY_API_READ_TOKEN: process.env.SANITY_API_READ_TOKEN,
    REVIEW_LINK_READ_TOKEN: process.env.REVIEW_LINK_READ_TOKEN,
    ANALYZE: process.env.ANALYZE,
    ISR_TOKEN: process.env.ISR_TOKEN,
    NODE_ENV: process.env.NODE_ENV,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    NEXT_PRIVATE_STOREFRONT_API_TOKEN: process.env.NEXT_PRIVATE_STOREFRONT_API_TOKEN,
    ALLOW_ROBOTS: process.env.ALLOW_ROBOTS,
    REVIEW_LINK_PRIVATE_KEY: process.env.REVIEW_LINK_PRIVATE_KEY,
    ENABLE_DZ_30_YEARS: process.env.ENABLE_DZ_30_YEARS,
    SKIP_BUILD_STATIC_GENERATION: process.env.SKIP_BUILD_STATIC_GENERATION,
    NEXT_PUBLIC_STATIC_HOST: process.env.NEXT_PUBLIC_STATIC_HOST,
    GLOBAL_REVALIDATE_VALUE: process.env.GLOBAL_REVALIDATE_VALUE,
    CAMPAIGNS_REVALIDATION_INTERVAL: process.env.CAMPAIGNS_REVALIDATION_INTERVAL,
    NEXT_PUBLIC_ENABLE_QUERY_OPTIMIZATION: process.env.NEXT_PUBLIC_ENABLE_QUERY_OPTIMIZATION,

    NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,

    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    NEXT_PUBLIC_SANITY_API_VERSION: process.env.NEXT_PUBLIC_SANITY_API_VERSION,

    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,

    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED,

    NEXT_PUBLIC_INQUIRY_ENDPOINT: process.env.NEXT_PUBLIC_INQUIRY_ENDPOINT,
    NEXT_PUBLIC_SUBSCRIBE_ENDPOINT: process.env.NEXT_PUBLIC_SUBSCRIBE_ENDPOINT,
    NEXT_PUBLIC_SUBMIT_CONSIGNMENT_ENDPOINT: process.env.NEXT_PUBLIC_SUBMIT_CONSIGNMENT_ENDPOINT,
    NEXT_PUBLIC_GET_PRESIGNED_URL_ENDPOINT: process.env.NEXT_PUBLIC_GET_PRESIGNED_URL_ENDPOINT,
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_NEWS_ID: process.env.NEXT_PUBLIC_SUBSCRIBE_TOPIC_NEWS_ID,
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_SALES_ID: process.env.NEXT_PUBLIC_SUBSCRIBE_TOPIC_SALES_ID,
    NEXT_PUBLIC_SUBSCRIBE_TOPIC_BOOKS_ID: process.env.NEXT_PUBLIC_SUBSCRIBE_TOPIC_BOOKS_ID,

    NEXT_PUBLIC_STOREFRONT_API_TOKEN: process.env.NEXT_PUBLIC_STOREFRONT_API_TOKEN,
    NEXT_PUBLIC_STORE_DOMAIN: process.env.NEXT_PUBLIC_STORE_DOMAIN,
    NEXT_PUBLIC_STOREFRONT_API_VERSION: process.env.NEXT_PUBLIC_STOREFRONT_API_VERSION,
    NEXT_PUBLIC_TRUSTARC_ID: process.env.NEXT_PUBLIC_TRUSTARC_ID,
    NEXT_PUBLIC_GOSC_RELEASE: process.env.NEXT_PUBLIC_GOSC_RELEASE,

    NEXT_PUBLIC_FEATURE_FLAG_EMAIL_GATE_ENABLED:
      process.env.NEXT_PUBLIC_FEATURE_FLAG_EMAIL_GATE_ENABLED,
    NEXT_PUBLIC_FEATURE_FLAG_BRAZE_ENABLED: process.env.NEXT_PUBLIC_FEATURE_FLAG_BRAZE_ENABLED,
    NEXT_PUBLIC_FEATURE_FLAG_LIGHTHOUSE_ANALYTICS_ENABLED:
      process.env.NEXT_PUBLIC_FEATURE_FLAG_LIGHTHOUSE_ANALYTICS_ENABLED,

    NEXT_PUBLIC_BRAZE_SDK_KEY: process.env.NEXT_PUBLIC_BRAZE_SDK_KEY,
    NEXT_PUBLIC_BRAZE_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_ENDPOINT,
    BRAZE_API_KEY: process.env.BRAZE_API_KEY,
    BRAZE_SUBSCRIBE_TOPIC_NEWS_ID: process.env.BRAZE_SUBSCRIBE_TOPIC_NEWS_ID,
    BRAZE_SUBSCRIBE_TOPIC_SALES_ID: process.env.BRAZE_SUBSCRIBE_TOPIC_SALES_ID,
    BRAZE_SUBSCRIBE_TOPIC_BOOKS_ID: process.env.BRAZE_SUBSCRIBE_TOPIC_BOOKS_ID,
    MAILCHIMP_MEMBERS_ENDPOINT: process.env.MAILCHIMP_MEMBERS_ENDPOINT,
    MAILCHIMP_ACCESS_TOKEN: process.env.MAILCHIMP_ACCESS_TOKEN,
  },
  skipValidation: !!process.env.SKIP_ENV_CHECK,
})

/**
 * Interprets "true" and "false" strings as boolean values.
 *
 * Workaround for reading boolean values from environment variables using `zod`.
 */
function booleanString() {
  return z.enum(['true', 'false']).transform((s) => s === 'true')
}
